import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import FooterAll from '../../components/footer2'
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import HelpYou from './help-you';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import ModalForm from '../utils/queryForm';
function SayaDesireResidency() {
    const canonical = window.location.href;
    useEffect(() => {
        // Handle hover effect
        const amenBoxes = document.querySelectorAll('.amenBox');

        amenBoxes.forEach((box) => {
            box.addEventListener('mouseenter', () => {
                const activeBox = document.querySelector('.amenBox.active');
                if (activeBox) {
                    activeBox.classList.remove('active');
                }
                box.classList.add('active');
            });
        });

        // Handle scroll effect
        const handleScroll = () => {
            const wScroll = window.scrollY;
            const amenitiesContainer = document.querySelector('.amenitiesContainer');

            if (amenitiesContainer) {
                const offsetTop = amenitiesContainer.offsetTop;
                const windowHeight = window.innerHeight;

                if (wScroll > offsetTop - windowHeight / 1.5) {
                    const translateElements = document.querySelectorAll('.amenitiesContainer .translate');

                    translateElements.forEach((element, i) => {
                        setTimeout(() => {
                            element.classList.add('doneTranslate');
                        }, 350 * (i + 1));
                    });
                }
            }
        };
        // animation
        const pageHeaderItems = document.querySelectorAll('.pageHeader ul li');
        pageHeaderItems.forEach((item, index) => {
            setTimeout(() => {
                item.classList.add('doneTranslate');
            }, 250 * (index + 1));
        });

        const unitsBtnItems = document.querySelectorAll('.unitsBtn .translate');
        unitsBtnItems.forEach((item, index) => {
            setTimeout(() => {
                item.classList.add('doneTranslate');
            }, 350 * (index + 1));
        });
        const headingItem = document.querySelector('.heading');
        setTimeout(() => {
            headingItem.classList.add('doneTranslate');
        }, 500);
        // animation ends
        new Swiper('.specSlider', {
            slidesPerView: "auto",
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                },
                620: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
        });
        const swiper1 = new Swiper('.extSlider', {
            slidesPerView: 'auto',
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                },
                620: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
        });
        const swiper2 = new Swiper('.news-slider', {
            slidesPerView: 'auto',
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                },
                620: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                },
            },
        });
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({
        modalType: '',
        pageName: 'Saya Desire Residency',
        projectName: 'Saya Desire Residency',
        brochure: '',
        brochureUrl: ''
    });

    const handleShowModal = () => {
        setModalData({
            modalType: '',
            pageName: 'Saya Desire Residency',
            projectName: 'Saya Desire Residency',
            brochure: '',
            brochureUrl: ''
        });
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);


    return (
        <div>
            <Navbar />
            <Helmet>
            <title>Saya Desire Residency | Top 2/3/4 BHK Apartments in Indirapuram, Ghaziabad</title>	
            <meta name="description" content="Discover luxurious living with Saya Desire Residency top 2/3/4 BHK apartments in Indirapuram, Ghaziabad. Experience modern amenities, spacious designs, and a prime location." />	
            <meta name="keywords" content="saya desire residency, luxury 2 bhk flats in indirapuram, top 3 bhk flats in indirapuram, best 4 bhk flats in indirapuram, luxury flats in ghaziabad, 2 bhk apartments in indirapuram, luxury 3 bhk apartments in indirapuram, luxury 4 bhk apartments in indirapuram, residential property in indirapuram, top residential property in ghaziabad" />
                
            <link rel="canonical" href={canonical}/>
            </Helmet>
            <div class="insideBanner w-100">
                <img src="https://sayahomes.com/assets/images/desire-banner.webp" alt='Saya Desire Residency Residential Property' title='Saya Desire Residency Residential Property' class="h-100 object-cover" />
                <div class="project-bannerBox container-lg">
                    <div class="pageLogo"><img src="https://sayahomes.com/assets/images/desire-logo.svg" alt="Saya Desire Residency Logo" title='Saya Desire Residency Logo' /><p>J9WJ+9RX, Indirapuram, Ahinsa Khand 2, Indirapuram, Ghaziabad</p></div>
                    <ul class="list-inline unitsBtn">
                        <li class="bottomTranslate translate "><div className='inner'>
                            <div class="imgBox"><img src="./assets/images/icons/building.svg" alt="Saya Desire Residency Tower" title='Saya Desire Residency Tower' /></div>
                            <p><small>BHK</small><span>2/3/4</span></p>
                        </div></li>
                        {/* <li class="bottomTranslate translate "><div className='inner'>
                            <div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/area.png" alt="Saya Desire Residency" /></div>
                            <p><small>Project Area</small><span>-</span></p>
                        </div></li> */}
                    </ul>
                </div>
                <div class="scrollDown">Scroll to Explore</div>
            </div>
            <div class="pageHeader bg-secondary">
                <div class="container-lg">
                    <div class="pageMenuBtn"><small></small></div>
                    <ul class="list-inline">
                        <li class="topTranslate "><Link to='/'><i class="fa fa-home"></i></Link></li>
                        <li class="topTranslate "><a href="#overview">Overview</a></li>
                        <li class="topTranslate "><a href="#amenities">Amenities</a></li>
                        <li class="topTranslate "><a href="#specifications">Specifications</a></li>
                        <li class="topTranslate "><a href="#gallery">Gallery</a></li>
                        <li class="topTranslate "><a href="#forms">Enquiry</a></li>
                        <li class="topTranslate "><a href="#location">Location</a></li>

                    </ul>
                </div>
            </div>
            <div class="breadcrumbContainer">
                <div class="container-lg">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">Saya Desire Residency</li>
                    </ol>
                </div>
            </div>
            <section class="w-100">
                <a name="overview"></a>
                <div id="overview" class="w-100 padding position-relative lightBg scrollto overviewBg" style={{ backgroundImage: "url(https://sayahomes.com/assets/images/saya-desire-residency/gallery/desire-banner-big1.webp)" }}>
                    <div class="container-lg">
                        <div class="row">

                            <div class="col-lg-12 projectsTag">
                                <div class="heading topTranslate translate doneTranslate">
                                    <h1 class="h2 text-serif">At the heart of Indirapuram, Saya brings to you Desire Residency. It’s a dream come true for those seeking luxurious homes in the posh locality of Indirapuram. Every apartment at Saya Desire Residency is unique and offers 2/3/4 BHK apartments.</h1>
                                    <hr />
                                    <p>In fact, the 3-side open plot offers every resident stunning view of Indirapuram for a memorable evening after a busy day. Together with dedicated yoga centres and landscaped greens, feel the stress vanish into thin air. Moreover, each apartment offers exceptional privacy while at the same time inviting you to a vibrant and a happening neighbourhood.</p>
                                </div>
                                <ul class="list-inline unitsBtn overview-unitsBtn bottomTranslate translate doneTranslate">
                                    <li class="bottomTranslate translate ">
                                        <div className='inner'>
                                            <div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/unit.png" alt="Saya Desire Residency Unit Icon" title='Saya Desire Residency Unit Icon' /></div>
                                            <p><small>Floors</small><span>13</span></p>
                                        </div>
                                    </li>
                                    <li class="bottomTranslate translate ">
                                        <div className='inner'>
                                            <div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/area.png" alt="Saya Desire Residency Area Icon" title='Saya Desire Residency Area Icon' /></div>
                                            <p><small>Status</small><span>Delivered </span></p>
                                        </div>
                                    </li>

                                    <li class="bottomTranslate translate "><div className='inner'>
                                        <div class="imgBox"><img src="./assets/images/icons/building-stroke.svg" alt="Saya Desire Residency Tower" title='Saya Desire Residency Tower' /></div>
                                        <p><small>BHK</small><span>2/3/4</span></p>
                                    </div></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="w-100">
                <a name="amenities"></a>
                <div id="amenities" class="w-100 padding amenitiesContainer" style={{ backgroundImage: "url(https://sayahomes.com/assets/images/saya-desire-residency/amenities.webp)" }}>

                    <div class="container-lg amenitiesWrapper">
                        <div class="amenBox">
                            <article class="w-100 bottomTranslate translate doneTranslate">
                                <h3>Amenities</h3>
                                <div class="allItems">
                                    <ul class="list-inline halfList">
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/club-house.png" alt="Club House" title='Club House' /></div><span>Clubhouse</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/party-lawn.png" alt="Party Lawn" title='Party Lawn' /></div><span>Party Lawn</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/gym.png" alt="GYM" title='GYM' /></div><span> Gym</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/kids-play-area1.png" alt=" Kids Play Area" title='Kids Play Area' /></div><span> Kids Play Area </span></li>
                                    </ul>
                                </div>
                                <span class="arrow">→</span>
                            </article>
                            <div class="amenBoxBg" style={{ backgroundColor: "#4792a5" }}></div>
                        </div>
                        <div class="amenBox">
                            <article class="w-100 bottomTranslate translate doneTranslate">
                                <h3>Features</h3>
                                <div class="allItems">
                                    <ul class="list-inline halfList">
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/lift.png" alt="Hi-speed Elevators" title='Hi-speed Elevators' /></div><span>Elevators</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/lobby.png" alt="A.C. Lobby" title='A.C. Lobby' /></div><span>A.C. Lobby</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/power-backup.png" alt="Power Back-up" title='Power Back-up' /></div><span>Power Back-up</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/parking.png" alt="Saya Desire Residency Parking" title='Saya Desire Residency Parking' /></div><span>Well-equipped clubhouse</span></li>
                                    </ul>
                                </div>
                                <span class="arrow">→</span>
                            </article>
                            <div class="amenBoxBg" style={{ backgroundColor: "#c38439" }}></div>
                        </div>
                        <div class="amenBox">
                            <article class="w-100 bottomTranslate translate doneTranslate">
                                <h3>Connectivity</h3>
                                <div class="allItems">
                                    <ul class="list-inline">
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/road.png" alt="Just of NH 24 0-15 Min" title='Just of NH 24 0-15 Min' /></div><span> Just off NH 24<br />0-15 Min</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/hospital.png" alt="Shani Gopal Hospital in Neighbourhood 5-10 Min"  title='Shani Gopal Hospital in Neighbourhood 5-10 Min' /></div><span> Shanti Gopal Hospital in neighbourhood<br />5-10 Min</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/mall.png" alt="Close to Shipra Mall 10-15 Min" title='Close to Shipra Mall 10-15 Min' /></div><span>Close to Shipra Mall<br />5-10 Min</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/ images/icons/railway.png" alt="Anand Vihar Railway Station 10-15 Min" title='Anand Vihar Railway Station 10-15 Min' /></div><span>Anand Vihar Railway Station<br />10-15 Min</span></li>
                                    </ul>
                                    <div class="readmore"><a href="#modalforms" data-toggle="modal" data-modaltype="View on Map" data-brochure="" data-pagename="saya-desire-residency.php" data-projectname="Saya Desire Residency" class="button"><span class="text">View on Map</span></a></div>
                                </div>
                                <span class="arrow">→</span>
                            </article>
                            <div class="amenBoxBg" style={{ backgroundColor: "#7c1e35" }}></div>
                        </div>
                        <div class="amenBox">
                            <article class="w-100 bottomTranslate translate doneTranslate">
                                <h3>Security</h3>
                                <div class="allItems">
                                    <ul class="list-inline">
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/security.png" alt="3-tier Cloud-based Security" title='3-tier Cloud-based Security' /></div><span>3-tier Cloud-based Security</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/cctv.png" alt="24X7 CCTV Surveillance" title='24X7 CCTV Surveillance' /></div><span>24x7 CCTV Surveillance</span></li>
                                    </ul>
                                </div>
                                <span class="arrow">→</span>
                            </article>
                            <div class="amenBoxBg" style={{ backgroundColor: "#2c4935" }}></div>
                        </div>
                        <div class="amenBox active">
                            <article class="w-100 bottomTranslate translate doneTranslate">
                                <h3>Neighbourhood</h3>
                                <div class="allItems">
                                    <ul class="list-inline">
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/mall.png" alt="Shopping Mall" title='Shopping Mall' /></div><span>Shopping Mall</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/park.png" alt="Park" title='Park' /></div><span>Park</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/hospital.png" alt="hospital" title='hospital' /></div><span>Hospitals</span></li>
                                        <li><div class="imgBox"><img src="https://sayahomes.com/assets/images/icons/school.png" alt="Schools" title='Schools' /></div><span>Schools</span></li>
                                    </ul>
                                </div>
                                <span class="arrow">→</span>
                            </article>
                            <div class="amenBoxBg" style={{ backgroundColor: "#a07963" }}></div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="w-100">
                <a name="specifications"></a>
                <div id="specifications" class="w-100 padding bg-secondary-light overflow-hidden">
                    <div class="container-lg">
                        <div class="heading mx-auto text-center">
                            <h6 class="text-orange">Specifications</h6>
                            <h2 class="h1 text-serif mb-0">The Luxury of Meticulous Planning</h2>
                        </div>
                        <div class="swiper specSlider w-100 swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                            <div class="swiper-wrapper" id="swiper-wrapper-9aa8a6bdbfffdb8f" aria-live="polite" style={{ transform: "translate3d(0px, 0px, 0px)" }}>
                                <div class="swiper-slide specSlide swiper-slide-active" role="group" aria-label="1 / 5" style={{ marginRight: "30px" }}>
                                    <div class="specDetails">
                                        <div class="spec-img"><img src="https://sayahomes.com/assets/images/saya-desire-residency/specifications/kitchen.webp" alt="Modular Kitchen" title='Modular Kitchen' /></div>
                                        <div class="inner">
                                            <div class="spec-title">
                                                <div class="img-fluid"><img src="https://sayahomes.com/assets/images/icons/kitchen.png" class="filter-dark" alt="Modular Kitchen" title='Modular Kitchen' /></div>
                                                <h6>Kitchen</h6>
                                            </div>
                                            <p>Designed to be the heart of the home.</p>
                                            <div class="scroller">
                                                <ul class="list-inline list">
                                                    <li><strong>Fitting</strong><span>Unique stainless steel modular kitchen of Godrej or equivalent brand with chimney and hob. Double bowel sinks with faucet.</span></li>
                                                    <li><strong>Flooring</strong><span>Designer ceramic tiles on 2 feet above the working top and 5 feet from the floor level on remaining wall, individual RO water system.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide specSlide swiper-slide-next" role="group" aria-label="2 / 5" style={{ marginRight: "30px" }}>
                                    <div class="specDetails">
                                        <div class="spec-img"><img src="https://sayahomes.com/assets/images/saya-desire-residency/specifications/wall.webp" alt="Walls" title='Walls' /></div>
                                        <div class="inner">
                                            <div class="spec-title">
                                                <div class="img-fluid"><img src="https://sayahomes.com/assets/images/icons/wall.png" class="filter-dark" alt="Walls" title='Walls' /></div>
                                                <h6>Walls</h6>
                                            </div>
                                            <p>Impeccably built to tell your home’s story.</p>
                                            <p>Plaster of Paris finish on walls &amp; ceilings. Designer pop ceilings in drawing room. Putty to be applied followed by coatings of acrylic emulsion paint in pleasing shades in room. One wall of drawing with wallpaper/textured paint.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide specSlide" role="group" aria-label="3 / 5" style={{ marginRight: "30px" }}>
                                    <div class="specDetails">
                                        <div class="spec-img"><img src="https://sayahomes.com/assets/images/saya-desire-residency/specifications/bathroom.webp" alt="Bathroom" title='Bathroom' /></div>
                                        <div class="inner">
                                            <div class="spec-title">
                                                <div class="img-fluid"><img src="https://sayahomes.com/assets/images/icons/master-toilet.png" class="filter-dark" alt="Master Toilet" title='Master Toilet' /></div>
                                                <h6>Bathroom</h6>
                                            </div>
                                            <p>A perfect place built for you to relax and unwind.</p>
                                            <div class="scroller">
                                                <ul class="list-inline list">
                                                    <li><strong>Fittings</strong><span>Plumbing done with Prince or equivalents UPVC &amp; CPVC pipes. Shower panels in all washrooms, TOTO or equivalent sanitary brand, mirror in all washrooms.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide specSlide" role="group" aria-label="4 / 5" style={{ marginRight: "30px" }}>
                                    <div class="specDetails">
                                        <div class="spec-img"><img src="https://sayahomes.com/assets/images/saya-desire-residency/specifications/woodwork.webp" alt="Woodwork" title='Woodwork' /></div>
                                        <div class="inner">
                                            <div class="spec-title">
                                                <div class="img-fluid"><img src="https://sayahomes.com/assets/images/icons/wood.svg" class="filter-dark" alt="Wood" title='Woods' /></div>
                                                <h6>Woodwork</h6>
                                            </div>
                                            <p>Interiors crafted with rich woodwork for an elegant finish.</p>
                                            <div class="scroller">
                                                <ul class="list-inline list">
                                                    <li><strong>Flooring</strong><span>1m X 1m Rak or equivalent designer vitrified tiles in Drawing/Dining room &amp; Kitchen. Rustic Porcelain tiles in bedrooms.</span></li>
                                                    <li><strong>Woodwork</strong><span>Cupboards with ISI plywood and board and Hettich hinges and designer handles, laminates of Sundek or Real Touch or Heritage or equivalent brand will be used. Inner side of cupboards will also be laminated.</span></li>
                                                    <li><strong>Doors &amp; Windows</strong><span>Door frames of size 8’ height &amp; section of 6”x2.5” of Maranti wood with Dulux or equivalent matte finish enamel. 32 mm flush doors with designer laminates. Good quality hardware. 16 gauge aluminium powder coated/UPVC outer door/window. Reflective window glasses. Good quality hardware.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide specSlide" role="group" aria-label="5 / 5" style={{ marginRight: "30px" }}>
                                    <div class="specDetails">
                                        <div class="spec-img"><img src="https://sayahomes.com/assets/images/saya-desire-residency/specifications/electrical.webp" alt="Highest Standards of Electrical Fittings" title='Highest Standards of Electrical Fittings'/></div>
                                        <div class="inner">
                                            <div class="spec-title">
                                                <div class="img-fluid"><img src="https://sayahomes.com/assets/images/icons/electrical.png" class="filter-dark" alt="Saya zenith Electricals" /></div>
                                                <h6>Electricals</h6>
                                            </div>
                                            <p>Highest standards of electrical fittings that make your home come alive.</p>
                                            <p>ISI PVC conduits, ISI multi-strand electrical wires, MCB boxes, MCB, modular switches, Telephone &amp; TV outlets in all rooms.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bottomControls">
                                <div class="swiper-button-prev solid swiper-button-disabled" tabindex="-1" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-9aa8a6bdbfffdb8f" aria-disabled="true"></div>
                                <div class="swiper-button-next solid" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-9aa8a6bdbfffdb8f" aria-disabled="false"></div>
                            </div>
                            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                    </div>
                </div>
            </section>
            <section class="w-100">
                <a name="gallery"></a>
                <div id="gallery" class="w-100 padding overflow-hidden">
                    <div class="container-lg">
                        <div class="heading mx-auto text-center">
                            <h6 class="text-orange">Gallery</h6>
                            <h2 class="h1 text-serif mb-0">A Glimpse of Excellence</h2>
                        </div>
                        <div class="pageHeadingContainer w-100">
                            <div class="scroller-heading">
                                <ul class="list-inline">
                                    <li><button class="galBtn galBtnActive" data-target="image">Images</button></li>

                                </ul>
                            </div>
                        </div>
                        <div class="galleryContainerBox w-100">
                            <div class="galleryContainer show w-100" id="galleryContainer-image">
                                <div class="swiper extSlider w-100 swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                    <div class="swiper-wrapper" id="swiper-wrapper-e9de1a21ce27206e" aria-live="polite" style={{ transform: "translate3d(0px, 0px, 0px)" }}>
                                        <div class="swiper-slide gallSlide swiper-slide-active" role="group" aria-label="1 / 6" style={{ marginRight: "30px" }}>
                                            <a href="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Saya-Desire-Residency-mobile.webp" data-magnify="gal" data-group="ext" data-caption="Image Gallery">
                                                <img src="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Saya-Desire-Residency-mobile.webp" alt="Saya Desire Residency Apartments" title='Saya Desire Residency Apartments' />
                                            </a>
                                        </div>
                                        <div class="swiper-slide gallSlide swiper-slide-next" role="group" aria-label="2 / 6" style={{ marginRight: "30px" }}>
                                            <a href="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Kitchen-desire.webp" data-magnify="gal" data-group="ext" data-caption="Image Gallery">
                                                <img src="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Kitchen-desire.webp" alt="Modular Kitchen" title='Modular Kitchen'/>
                                            </a>
                                        </div>
                                        <div class="swiper-slide gallSlide" role="group" aria-label="3 / 6" style={{ marginRight: "30px" }}>
                                            <a href="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Wall.webp" data-magnify="gal" data-group="ext" data-caption="Image Gallery">
                                                <img src="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Wall.webp" alt="Wall" title='Wall' />
                                            </a>
                                        </div>
                                        <div class="swiper-slide gallSlide" role="group" aria-label="4 / 6" style={{ marginRight: "30px" }}>
                                            <a href="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Washroom.webp" data-magnify="gal" data-group="ext" data-caption="Image Gallery">
                                                <img src="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Washroom.webp" alt="Washroom" title='Washroom' />
                                            </a>
                                        </div>
                                        <div class="swiper-slide gallSlide" role="group" aria-label="5 / 6" style={{ marginRight: "30px" }}>
                                            <a href="https://sayahomes.com/assets/images/saya-desire-residency/gallery/woodwork-desire.webp" data-magnify="gal" data-group="ext" data-caption="Image Gallery">
                                                <img src="https://sayahomes.com/assets/images/saya-desire-residency/gallery/woodwork-desire.webp" alt="Woodwark Desire" title='Woodwark Desire' />
                                            </a>
                                        </div>
                                        <div class="swiper-slide gallSlide" role="group" aria-label="6 / 6" style={{ marginRight: "30px" }}>
                                            <a href="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Electricals-desire.webp" data-magnify="gal" data-group="ext" data-caption="Image Gallery">
                                                <img src="https://sayahomes.com/assets/images/saya-desire-residency/gallery/Electricals-desire.webp" alt="Electrical Switch" title='Electrical Switch' />
                                            </a>
                                        </div>
                                    </div>

                                    <div class="swiper-button-next" id="int_next" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-e9de1a21ce27206e" aria-disabled="false"></div>
                                    <div class="swiper-button-prev swiper-button-disabled" id="int_prev" tabindex="-1" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-e9de1a21ce27206e" aria-disabled="true"></div>
                                    <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                            </div>
                            <div class="galleryContainer w-100" id="galleryContainer-video">
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div>
                <section className="w-100">
                    <a name="forms"></a>
                    <div id="forms" className="w-100 botpadding bg-orange">
                        <div className="container-lg">
                            <div className="formBtnContainer bg-golden">
                                <div className="formText">
                                    <div className="heading mx-auto text-center">
                                        <h2 className="text-serif mb-0">We’re Glad to Help</h2>
                                    </div>
                                    <p className='text-dark'>We are here to help you 24*7. From arranging a callback to booking your dream home, we are equipped with every kind of Support.</p>
                                </div>
                                <div className="readmore mx-auto w-fit d-flex justify-content-center">
                                    <a
                                        href="#modalforms"
                                        data-toggle="modal"
                                        data-modaltype="Schedule a Call"
                                        
                                        data-projectname="Saya Desire Residency"
                                        data-brochure="brochure_url_here"
                                        data-brochureurl="brochure_url_here"
                                        onClick={handleShowModal}
                                        className="button justify-content-center"
                                    >
                                        <span className="icon"><i className="fa-solid fa-phone"></i></span>
                                        <span className="text">Schedule a Call</span>
                                        <span className="icon"><i className="fa-solid fa-phone"></i></span>
                                    </a>
                                    <a
                                        href="#modalforms"
                                        data-toggle="modal"
                                        data-modaltype="Plan Site Visit"
                                        
                                        data-projectname="Saya Desire Residency"
                                        data-brochure="brochure_url_here"
                                        data-brochureurl="brochure_url_here"
                                        onClick={handleShowModal}
                                        className="button justify-content-center"
                                    >
                                        <span className="icon"><i className="fa-solid fa-car"></i></span>
                                        <span className="text">Plan Site Visit</span>
                                        <span className="icon"><i className="fa-solid fa-car"></i></span>
                                    </a>
                                    <a
                                        href="#modalforms"
                                        data-toggle="modal"
                                        data-modaltype="Enquire Now"
                                        
                                        data-projectname="Saya Desire Residency"
                                        data-brochure="brochure_url_here"
                                        data-brochureurl="brochure_url_here"
                                        onClick={handleShowModal}
                                        className="button justify-content-center"
                                    >
                                        <span className="icon"><i className="fa-solid fa-envelope"></i></span>
                                        <span className="text">Enquire Now</span>
                                        <span className="icon"><i className="fa-solid fa-envelope"></i></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ModalForm showModal={showModal} modalData={modalData} handleCloseModal={handleCloseModal} />
            </div>
            <section class="w-100">
                <a name="location"></a>
                <div id="location" class="w-100 padding overflow-hidden">
                    <div class="container-lg">
                        <div class="row gap-row">
                            <div class="col-lg-8 projectMap">
                                <div class="inside">
                                    <div class="heading mx-auto text-center  mx-lg-0 text-lg-left">
                                        <h2 class="h1 text-serif mb-0">Location</h2>
                                    </div>
                                    <div class="locationmap">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7002.916675530079!2d77.382102!3d28.645992!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1daeee376071162!2sDesire%20Residency!5e0!3m2!1sen!2sin!4v1654066249402!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" data-lf-form-tracking-inspected-p1e024brarp4gb6d="true" data-lf-yt-playback-inspected-p1e024brarp4gb6d="true" data-lf-vimeo-playback-inspected-p1e024brarp4gb6d="true"></iframe>
                                        <p class="mt-3"><b><i class="fa-solid fa-map-marker-alt"></i> J9WJ+9RX, Ahinsa Khand 2, Indirapuram, Ghaziabad, Uttar Pradesh 201014</b></p>
                                    </div>
                                    <div class="readmore"><a href="https://maps.app.goo.gl/oDP4ugE3F2YUvRmk7" target="_blank" class="button justify-content-lg-start">
                                        <span class="icon"><i class="fa-solid fa-map-marker-alt"></i></span>
                                        <span class="text">View Location Map</span>
                                        <span class="icon"><i class="fa-solid fa-map-marker-alt"></i></span>
                                    </a></div>
                                </div>
                            </div>
                            <div class="col-lg-4 projectAwards">
                                <div class="inside">
                                    <div class="heading mx-auto text-center  mx-lg-0 text-lg-left">
                                        <h2 class="h1 text-serif mb-0">Awards</h2>
                                    </div>
                                    <div class="swiper news-slider blogContainer swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                        <div class="swiper-wrapper" id="swiper-wrapper-5ece64d8fff9ab97" aria-live="polite" style={{transform: "translate3d(0px, 0px, 0px)"}}>
                                            <div class="swiper-slide newsBox blogBox swiper-slide-active" role="group" aria-label="1 / 3" style={{width: "226px", marginRight: "10px"}}>
                                                <a href="https://sayahomes.com/assets/images/awards/3563c150-0025-426b-a2e0-30c6068e3c61.webp" class="inner h-100" data-magnify="awards" data-group="awards" data-caption="Excellence in Luxury Segment Homes">
                                                    <div class="img-fluid"><img src="https://sayahomes.com/assets/images/awards/3563c150-0025-426b-a2e0-30c6068e3c61-th.webp" alt="Excellence in Luxury Segment Homes" class="h-100 object-cover" title="Excellence in Luxury Segment Homes" /></div>
                                                    <article>
                                                        <h5 class="news-title mt-4">Excellence in Luxury Segment...</h5>
                                                        <p class="mb-0">ET Now Realty Conclave 2024</p>
                                                    </article>
                                                </a>
                                            </div>
                                            <div class="swiper-slide newsBox blogBox swiper-slide-next" role="group" aria-label="2 / 3" style={{width: "226px", marginRight: "10px"}}>
                                                <a href="./images/awards/11f3e7c5-30cd-4a98-8190-341ee4a0fcb0.webp" class="inner h-100" data-magnify="awards" data-group="awards" data-caption="Real Estate Developer of the Year">
                                                    <div class="img-fluid"><img src="https://sayahomes.com/assets/images/awards/11f3e7c5-30cd-4a98-8190-341ee4a0fcb0-th.webp" alt="Real Estate Developer of The Year" class="h-100 object-cover" title="Real Estate in Developer of The Year" /></div>
                                                    <article>
                                                        <h5 class="news-title mt-4">Real Estate Developer of...</h5>
                                                        <p class="mb-0">ET Now Realty Conclave 2024</p>
                                                    </article>
                                                </a>
                                            </div>
                                            <div class="swiper-slide newsBox blogBox" role="group" aria-label="3 / 3" style={{width: "226px", marginRight: "10px"}}>
                                                <a href="./images/awards/1.webp" class="inner h-100" data-magnify="awards" data-group="awards" data-caption="ICONIC Real Estate Brand of The Year - Saya Group">
                                                    <div class="img-fluid"><img src="https://sayahomes.com/assets/images/awards/th1.webp" alt="Times Business Awards" class="h-100 object-cover" title="Times Business Awards" /></div>
                                                    <article>
                                                        <h5 class="news-title mt-4">Iconic Real Estate Brand of...</h5>
                                                        <p class="mb-0">Times Business Awards, North 2024</p>
                                                    </article>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="bottomControls justify-content-lg-start px-lg-0">
                                            <div class="swiper-button-prev solid swiper-button-disabled" tabindex="-1" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-5ece64d8fff9ab97" aria-disabled="true"></div>
                                            <div class="swiper-button-next solid" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-5ece64d8fff9ab97" aria-disabled="false"></div>
                                        </div>
                                        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterAll />
        </div>
    )
}

export default SayaDesireResidency