import React, { useState } from 'react';
import Navbar from '../components/navbar';
import FooterAll from '../components/footer2';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { handleSubmit4QT } from './utils/4QT-api';

function ChannelPartnerRegistration() {
  const canonical = window.location.href;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    project_name: '',
    city: '',
    state: '',
    comments: '',
    query_nature: 'Channel Partner Registration',
    created: new Date().toISOString() // Current date and time
  });
  const [successMessage, setSuccessMessage] = useState('');
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formatDateForMySQL = (date) => {
    const d = new Date(date);
    return d.toISOString().slice(0, 19).replace('T', ' ');
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    
    // Format the created date for MySQL
    const formattedDate = formatDateForMySQL(new Date());
    
    const dataToSend = { ...formData, created: formattedDate };
    
    try {
      const response = await axios.post('https://ecis.in/apis/sayahomes/customerSupport/upload', dataToSend);
      await handleSubmit4QT(e, formData.mobile, formData.email, formData.name, formData.query_nature, formData.query_nature, formData.query_nature);
      console.log('Form submitted successfully:', response.data);
      setSuccessMessage('Your request has been submitted successfully!'); // Set success message

      // Clear form data
      setFormData({
        name: '',
        email: '',
        mobile: '',
        project_name: '',
        city: '',
        state: '',
        comments: '',
        query_nature: 'Channel Partner Registration',
        created: new Date().toISOString()
      });
      
    } catch (error) {
      console.error('Error submitting form:', error);
      setSuccessMessage('There was an error submitting your request.'); // Set error message
    }
  };

  return (
    <div>
      <Helmet>
      <title>Saya Homes | Register as a Channel Partner</title>
      <meta name="description" content="Channel Partner Registration" />
      <meta name="keywords" content="Channel Partner Registration" />
      <link rel="canonical" href={canonical}/>
      </Helmet>
      <Navbar />
      <div className="emptyBox"></div>
      <div className="pageTitle py-5 bg-orange w-100">
        <div className="container-lg">
          <h1 className="display-4 mb-0">Register as a Channel Partner</h1>
        </div>
      </div>
      <div className="breadcrumbContainer">
        <div className="container-lg">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Register as a Channel Partner</li>
          </ol>
        </div>
      </div>
      <div className="w-100 padding position-relative">
        <div className="container-lg">
          <div className="row gap-row justify-content-center">
            <div className="col-sm-12">
              <div className="card contactCard shadow-sm h-100">
                <div className="card-body">
                  <span className="text-danger constatus">{successMessage}</span>
                  <form id="contactForm" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label htmlFor="name">Name *</label>
                        <div className='form-row gap-form-row'>
                          <div className='col-2'>
                            <select className='form-control form-control-bg'>
                              <option value={'Mr'}>Mr</option>
                              <option value={'Ms'}>Ms</option>
                              <option value={'Mrs'}>Mrs</option>
                              <option value={'Dr'}>Dr</option>
                            </select>
                          </div>
                          <div className='col-10'>
                            <input type="text" className="form-control form-control-bg" name="name" id="name" value={formData.name} onChange={handleChange} required />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="mobile">Mobile *</label>
                        <div className='form-row gap-form-row'>
                          <div className='col-9'>
                            <input type="text" className="form-control form-control-bg" name="mobile" id="mobile" value={formData.mobile} onChange={handleChange} required />
                          </div>
                          <div className='col-3'>
                            <button className='verify-btn btn bg-golden rounded-0'>Verify Mobile</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="email">Email *</label>
                        <input type="email" className="form-control form-control-bg" name="email" id="email" value={formData.email} onChange={handleChange} required />
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="email">Company Name *</label>
                        <input type="text" className="form-control form-control-bg" name="companyname" id="companyname" value={formData.companyname} onChange={handleChange} required />
                      </div>
                      <div className="col-md-12 form-group">
                        <label htmlFor="email">Upload Document *</label>
                        <input type="file" className="form-control form-control-bg" name="document" id="document" value={formData.document} onChange={handleChange} required />
                      </div>
                      
                      <div className="col-md-12 readmore">
                        <input type="hidden" id="channel_registration" name="channel_registration" value="active" />
                        <button type="submit" className="button justify-content-start" id="contsubmti">
                          <span className="icon"><i className="fa-solid fa-arrow-right"></i></span>
                          <span className="text" id="contidbtn">Submit</span>
                          <span className="icon"><i className="fa-solid fa-arrow-right"></i></span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterAll />
    </div>
  );
}

export default ChannelPartnerRegistration;
