import React, { useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import FooterAll from '../../components/footer2';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function MediaBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [Recentblogs, setRecentBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const canonical = window.location.href;
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6; 


  useEffect(() => {
    try {
      fetchBlogs();
    } catch (error) {
      console.log(error);
    }
  }, []);

 
  const fetchBlogs = async () => {
    try {
      const result = await axios.get('https://ecis.in/apis/sayahomes/blog/get');
      const result1 = await axios.get('https://ecis.in/apis/sayahomes/blog/getRecentBlogs');
      setRecentBlogs(result1.data);
      const sorted = result.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setBlogs(sorted);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


  const filteredBlogs = blogs.filter((blog) =>
    blog.heading.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);


  return (
    <div>
      <Helmet>
        <title>Blogs by Saya Homes | Residential & Commercial Projects in Delhi NCR</title>
        <meta
          name="description"
          content="Stay updated on the latest real estate residential and commercial projects in Delhi NCR with insightful blogs from Saya Homes."
        />
        <meta
          name="keywords"
          content="saya homes blogs, residential projects in ghaziabad, residential projects in noida, residential projects in greater noida west, commercial projects in greater noida west, commercial property in greater noida west"
        />
         <link rel="canonical" href={canonical}/>
      </Helmet>
      <Navbar />
      <div className="emptyBox"></div>
      <div className="pageTitle py-5 bg-orange w-100">
        <div className="container-lg">
          <h1 className="display-4 mb-0">Saya Blogs</h1>
        </div>
      </div>
      <div className="breadcrumbContainer">
        <div className="container-lg">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Blogs</li>
          </ol>
        </div>
      </div>

      {isLoading ? (
        // Loading indicator
        <div className="text-center py-5">
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <div className="w-100 padding pb-0 position-relative">
            <div className="container-lg">
              <div className="heading">
                <h2 className="h1 text-serif mb-0">Latest Blogs</h2>
              </div>
              <div className="latest-blogs-container">
                <div className="row gap-row">
                  {Recentblogs.slice(0, 2).map((blog, index) => (
                    <div className={`col-lg-${index === 0 ? '7' : '5'} latest-blog-box`} key={blog.slugurl}>
                      <div className="inner">
                        <figure className="h-100">
                          
                          <img
                            src={`https://www.sayahomes.com/uploads/blog-images/${blog.image}`}
                            alt={blog.heading}
                            className="h-100 object-cover"
                          />
                        </figure>
                        <article>
                          <Link to={`/blog-details/${blog.slugurl}`} className="news-title">
                            <p className="h5 mb-0 font-weight-bold">{blog.heading}</p>
                          </Link>
                          <div className="blog-box-category d-flex flex-wrap align-items-center justify-content-between">
                            <Link to="#" className="category-btn">Real Estate</Link>
                            <div className="socialBtn shareBtns d-flex">
                            <Link to="https://www.facebook.com/sayahomesofficial" target='_blank'><i className="fab fa-facebook-f"></i></Link>
                            <Link to="https://www.instagram.com/sayahomes/" target='_blank'><i className="fab fa-instagram"></i></Link>
                            <Link to="https://www.linkedin.com/company/saya-homes target='_blank'"><i className="fab fa-linkedin-in"></i></Link>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="w-100 padding position-relative">
            <div className="container-lg">
              <div className="row gap-row">
                <div className="col-lg-8 inside-blogsArchive">
                  <div className="row gap-row">
                    {filteredBlogs.map((blog) => (
                      <div className="col-md-6 inside-blogBox" key={blog.slugurl}>
                        <div className="inner h-100">
                          <div className="img-fluid">
                            <Link to={`/blog-details/${blog.slugurl}`}>
                              <img
                                src={`https://www.sayahomes.com/uploads/blog-images/${blog.image}`}
                                alt={blog.heading}
                                className="h-100 object-cover"
                                title='Real Estate'
                              />
                            </Link>
                            <div className="blog-box-category position-absolute">
                              <Link to="#" className="category-btn">
                                {blog.byteam}
                              </Link>
                            </div>
                          </div>
                          <article>
                            <Link to={`/blog-details/${blog.slugurl}`}>
                              <h5 className="news-title mb-2">{blog.heading}</h5>
                              <div className="date justify-content-between">
                                <small>
                                  {new Date(blog.date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}
                                </small>
                              </div>
                            </Link>
                          </article>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <aside className="col-lg-4 inside-blogs-aside">
                  <div className="inner">
                    <div className="blog-asideBox">
                      <div className="form-group d-flex mb-0">
                        <input
                          type="text"
                          name="blog_search"
                          id="blog_search"
                          className="form-control"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-asideBox">
                  <div className="heading mb-3">
                    <h4 className="text-serif mb-0">Follow Us</h4>
                  </div>
                  <div className="socialBtn shareBtns d-flex">
                    <Link to="https://www.facebook.com/sayahomesofficial" target='_blank'><i className="fab fa-facebook-f"></i></Link>
                    <Link to="https://www.instagram.com/sayahomes/" target='_blank'><i className="fab fa-instagram"></i></Link>
                    <Link to="https://www.linkedin.com/company/saya-homes target='_blank'"><i className="fab fa-linkedin-in"></i></Link>
                  </div>
                </div>
                <div className="blog-asideBox">
                  <form method="POST" id="subsforms">
                    <span className="substatus text-danger py-2"></span>
                    <div className="form-group d-flex mb-0">
                      <input type="text" name="subscribe" id="subscribe" className="form-control" placeholder="Email" />
                      <input type="hidden" name="subsactive" value="active" />
                      <button id="subscribeBtn" type="submit" className="inlineBtn">Subscribe</button>
                    </div>
                  </form>
                </div>
              
                </aside>
              </div>
            </div>            
          </div> */}
          <div className="w-100 padding position-relative">
            <div className="container-lg">
              <div className="row gap-row">
                <div className="col-lg-8 inside-blogsArchive">
                  <div className="row gap-row">
                    {currentBlogs.map((blog) => (
                      <div className="col-md-6 inside-blogBox" key={blog.slugurl}>
                        <div className="inner h-100">
                          <div className="img-fluid">
                            <Link to={`/blog-details/${blog.slugurl}`}>
                              <img
                                src={`https://www.sayahomes.com/uploads/blog-images/${blog.image}`}
                                alt={blog.heading}
                                className="h-100 object-cover"
                                title="Real Estate"
                              />
                            </Link>
                            <div className="blog-box-category position-absolute">
                              <Link to="#" className="category-btn">
                                {blog.byteam}
                              </Link>
                            </div>
                          </div>
                          <article>
                            <Link to={`/blog-details/${blog.slugurl}`}>
                              <h5 className="news-title mb-2">{blog.heading}</h5>
                              <div className="date justify-content-between">
                                <small>
                                  {new Date(blog.date).toLocaleDateString('en-US', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                  })}
                                </small>
                              </div>
                            </Link>
                          </article>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Pagination Controls */}
                  <div className="pagination-controls d-flex justify-content-center mt-4">
                    <button
                      className="btn btn-primary me-2"
                      onClick={prevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <div>
                      {Array.from({ length: totalPages }, (_, index) => (
                        <button
                          key={index + 1}
                          className={`btn btn-outline-primary mx-1 ${currentPage === index + 1 ? 'active' : ''}`}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      ))}
                    </div>
                    <button
                      className="btn btn-primary ms-2"
                      onClick={nextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
                <aside className="col-lg-4 inside-blogs-aside">
                  <div className="inner">
                    <div className="blog-asideBox">
                      <div className="form-group d-flex mb-0">
                        <input
                          type="text"
                          name="blog_search"
                          id="blog_search"
                          className="form-control"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-asideBox">
                  <div className="heading mb-3">
                    <h4 className="text-serif mb-0">Follow Us</h4>
                  </div>
                  <div className="socialBtn shareBtns d-flex">
                    <Link to="https://www.facebook.com/sayahomesofficial" target='_blank'><i className="fab fa-facebook-f"></i></Link>
                    <Link to="https://www.instagram.com/sayahomes/" target='_blank'><i className="fab fa-instagram"></i></Link>
                    <Link to="https://www.linkedin.com/company/saya-homes target='_blank'"><i className="fab fa-linkedin-in"></i></Link>
                  </div>
                </div>
                <div className="blog-asideBox">
                  <form method="POST" id="subsforms">
                    <span className="substatus text-danger py-2"></span>
                    <div className="form-group d-flex mb-0">
                      <input type="text" name="subscribe" id="subscribe" className="form-control" placeholder="Email" />
                      <input type="hidden" name="subsactive" value="active" />
                      <button id="subscribeBtn" type="submit" className="inlineBtn">Subscribe</button>
                    </div>
                  </form>
                </div>
              
                </aside>
              </div>
            </div>
          </div>
        </>
      )}
      <FooterAll />
    </div>
  );
}

export default MediaBlogs;
