export const handleSubmit4QT = async ({
    mobile,
    email,
    name,
    projectName,
    pagename,
    mbrochure,
    url = "https://saya06.4erealty.com/WebCreate.aspx",
  }) => {
    const uid = Math.floor(Math.random() * (10000 - 10 + 1)) + 10;
  
    const data = new URLSearchParams({
      UID: 'fourqt',
      PWD: 'wn9mxO76f34=',
      Channel: 'MS',
      Src: 'Website',
      ISD: '91',
      Mob: mobile,
      Email: email,
      name: name,
      City: '',
      Location: '',
      Project: projectName,
      Utm_Source: pagename,
      Utm_Medium: '',
      Utm_Camp: '',
      Remark: mbrochure,
      url: '',
      UniqueId: uid.toString(),
    });
  
    const fullUrl = `${url}?${data.toString()}`;
  
    try {
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      console.log(response)
  
      const responseData = await response.text();
      if (response.ok) {
        console.log('Request Successful:', responseData);
        return responseData;
      } else {
        console.error('Request Error:', responseData);
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error('Request Error:', error);
      throw error;
    }
  };
  