import { useEffect, useState } from "react";

export default function BannerADD() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 5000);

        return () => clearTimeout(timer); 
    }, []);

    const handleCloseModal = () => {
        setShowModal(false); 
    };

    return (
        <>
            {showModal && (
                <div
                    className="modal fade show"
                    id="exampleModalBanner"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-modal="true"
                    style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close d-flex align-items-center justify-content-center"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCloseModal}
                                style={{ position: "absolute", top: "-20px", right: "0px", fontSize: "1.5rem" }}
                            >
                                ×
                            </button>
                            <div className="row no-gutters">
                                <div className="col-lg-12 col-sm-8">
                                        <a target="_blank" href="https://sayasouthx.com/cinepolis/">
                                        <img
                                            src="https://ecis.in/saya/assets/images/banner-add.jpg" 
                                            alt="Promotional Banner"
                                            style={{ width: "100%", height: "auto",}}
                                            
                                        /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
